<template>
  <div
    style="width: 100%; height: 100%;"
  >
    <!-- <v-img
      class="d-flex align-center"
      aspect-ratio="1.7"
      :src="require(`@/assets/images/svg/BRIEFING_BANNER_SITE_2.jpg`)"
    > -->
    <div
      class="d-flex imageBg"
      style="height: 100%; position: relative; width: 100%;"
    >
      <div
        style="position: absolute; top: 86px; left: 50%; transform: translate(-50%, -50%);"
      >
        <v-img
          width="207"
          height="72"
          src="@/assets/images/svg/logo-login-sem-fundo.svg"
        >
        </v-img>
      </div>
      <div
        class=" containerBanner2"
      >
        <div>
          <h1 class="header">
            Explore <br> os sistemas <br> de inovação!
          </h1>
          <h2 class="subHeader">
            Tecnologia, desempenho <br> e confiabilidade.
          </h2>
        </div>
        <div class="container-user">
          <v-card-text class="form-title">
            <p
              class="mb-2"
              style=" font-size: 20px; font-weight: 700; line-height: 24.2px;"
            >
              Bem-vindo ao Motoca Systems!
            </p>

            <p class="mb-2">
              Insira os seus dados para acessar.
            </p>
          </v-card-text>

          <v-card-text v-if="error401">
            <v-alert
              text
              color="error"
            >
              <small class="d-block mb-0"> <strong>Email</strong> ou <strong>Senha</strong> inválidos. </small>
            </v-alert>
          </v-card-text>

          <v-card-text>
            <v-form
              class=" px-7"
              @submit.prevent
            >
              <v-text-field
                v-model="email"
                label="CPF"
                class=""
                color="white"
                type="email"
                data-test="email-input"
                autocomplete="username"
                outlined
              />

              <v-text-field
                v-model="password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Senha"
                placeholder="Senha"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details="auto"
                class="mb-2"
                color="white"
                data-test="password-input"
                autocomplete="current-password"
                @click:append="isPasswordVisible = !isPasswordVisible"
              />

              <v-btn
                :loading="isLoading"
                block
                type="submit"
                class="mt-6 py-5"
                style="background: linear-gradient(to right, #E40D1A, #BA0911); color: #ffffff; font-weight: 600; font-size: 16px; line-height: 18.15px;"
                data-test="button-login"
                @click="login"
              >
                ACESSAR
              </v-btn>
            </v-form>
          </v-card-text>

          <div
            class="container-director"
            @click="$router.push({ name: 'director-channel' })"
          >
            <img
              src="@/assets/images/svg/chat-icon.svg"
              alt=""
            />
            <span>
              Fale com o Diretor
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="logoPosition">
        <v-img
          width="170px"
          height="60px"
          :src="require(`@/assets/images/svg/motoca-systems-logo-login.svg`)"
          alt="logo"
        />
        <div></div>
      </div> -->
    <!-- </v-img> -->
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import axiosIns from '@axios'
import {
  mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGithub, mdiGoogle, mdiTwitter,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')

    const error401 = ref(false)
    const isLoading = ref(false)

    const typeUser = ref('')

    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,
      isLoading,
      error401,
      typeUser,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
  methods: {
    async login() {
      this.isLoading = true
      this.error401 = false
      await axiosIns
        .post('/api/login', {
          login: this.email,
          password: this.password,
        })
        .then(async res => {
          const { access_token: accessToken, user } = res.data.data
          localStorage.setItem('accessToken', accessToken)
          localStorage.setItem('username', user.name)
          localStorage.setItem('userRole', user.supervisor)
          localStorage.setItem('congratulation', true)

          await this.$router.push({ name: 'home' })
        })
        .catch(err => {
          this.isLoading = false

          if (err?.response?.status === 401) {
            this.error401 = true
          }
        })

      this.$router.go()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

.container-director {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  gap: 10px;

  span {color: white;}
  cursor: pointer;

  span:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0.459);
  }
}
/* .background{
  background: #EF7721;
} */

.container-login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 100px;

  @media (max-width: 600px) {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.container-user {
  width: 400px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #0C0C0CD4;
  color: #fff;
  border-radius: 20px;

  @media (max-width: 600px) {
    margin-right: 0px;
  }

  @media (max-width: 400px) {
    width: 350px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #000000D9;
    color: #fff;
    border-radius: 10px;
  }
}
.logoPosition{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  @media (max-height: 710px) {
    top: 20px;
  }
  @media (max-width: 713px) {
    transform: translateX(-70%);
  }
  @media (max-height: 661px) and (max-width: 1307px) {
    display: none;
  }
}

.rotate-tornado span{
  font-weight: bolder;
}

.text-logo{
  color: #fff
}

.form-title {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bannerLogin {
  width: 100%;

  @media (max-width: 1307px) {
    display: none;
  }
}

.containerBanner2{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 75px;
  padding-left: 123px;
  width: 100%;
  @media (max-width: 713px) {
    padding-right: 0px;
    justify-content: center;
  }
}
.imageBg {
  background-image: url('../assets/images/svg/bannerBackgroundLogin.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
}

.subHeader {
  color: #6E1E1F;
  font-size: 36px;
  font-weight: 500;
  font-family: 'Libre Franklin', serif;
}

.header{
  color: #DA170F;
  font-size: 96px;
  font-weight: 900;
  line-height: 94.2%;
  letter-spacing: 2%;
  font-family: 'Libre Franklin', serif;
}

@media (max-width: 1266px) {
  .subHeader {
    font-size: 34px;
  }

  .header {
    font-size: 74px;
  }

  .containerBanner2{
    padding-right: 35px;
    padding-left: 43px;
  }
}

@media (max-width: 950px) {
  .subHeader {
    font-size: 18px;
  }

  .header {
    font-size: 48px;
  }
  .containerBanner2{
    padding-right: 15px;
    padding-left: 23px;
  }
}
@media (max-width: 800px) {
  .subHeader {
    display: none;
  }

  .header {
    display: none;
  }
  .containerBanner2{
    justify-content: center;
    padding-right: 0px;
    padding-left: 0px;
  }
}

/* .containerBanner{
  height: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 350px;
  width: 100%;
  margin: 150px 100px 150px 0px;
  @media (max-width: 1307px) {
    justify-content: center
  }
  @media (max-width: 1712px) {
    gap: 250px;
    margin: 150px 50px 150px 0px;
  }
  @media (max-width: 1554px) {
    gap: 200px;
    margin: 150px 0px 150px 0px;
  }
  @media (max-width: 1428px) {
    gap: 120px;
    margin: 150px 0px 150px 0px;
  }
  @media (max-width: 1352px) {
    gap: 100px;
    margin: 150px 0px 150px 0px;
  }
  @media (max-width: 1351px) {
    gap: 50px;
    margin: 150px 0px 150px 0px;
  }
} */

</style>
